Vue.use(window.VueAwesomeSwiper)
window.eventBus = new Vue();

var isDev = false;
// axios.defaults.baseURL = 'http://192.168.110.207'
// axios.defaults.baseURL = 'http://192.168.110.106'
// axios.defaults.baseURL = 'https://www.zkjan.com'
// axios.defaults.baseURL = 'http://simprod.api.anqfw.com'
// axios.defaults.baseURL = 'http://api.anqfw.com'
var protocol = window.location.protocol;
var prodUrl = protocol + '//api.anqfw.com';//线上环境
var devUrl = protocol + '//simprod.api.anqfw.com';//测试环境

axios.defaults.baseURL = isDev ? devUrl :prodUrl;

axios.interceptors.request.use(function(config){
  var Authorization = localStorage.getItem('Authorization')
  if(Authorization){
    config.headers['Authorization'] = Authorization
  }
  var CompanyId = localStorage.getItem('companyId')
  if(CompanyId){
    if(!config.headers['CompanyId']){
      config.headers['CompanyId'] = CompanyId
    }
  }
  return config
},
function(err){
  return Promise.reject(err)
})


axios.interceptors.response.use(
  function(res){
    if(res.data.status==100 || res.data.status==0){
      return res.data
    }else {
      // var msg = (res.data&&res.data.message) || '结果异常';
      // message.default.warning(msg)
      return Promise.reject(res.data)
    }
  },
  function(err){
    // console.log(err)
    return Promise.reject(err)
  }
)


/**
*获取url参数
*@param {string} key 参数的key
*/
Vue.prototype.getQueryString = function (key) {
	var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)");
	var r = window.location.search.substr(1).match(reg);
	if (r != null) {
		return decodeURI(r[2]);
	}
	return null;
};
/**
 *验证是否登录
 */
 Vue.prototype.checkLogin = function () {
	if(!Vue.prototype.isLogin){
		return false
	}else{
		return true
	}
};
/**
 *a标签登录
 */
Vue.prototype.goLogin = function(e,url) {
  e.preventDefault()
  e.stopPropagation()
  window.location.href = url + '&href=' + encodeURIComponent(window.location.href)
}
/**
 *a点击
 */
Vue.prototype.aClick = function(e,linkdress){
  if(!linkdress){
    e.preventDefault()
    e.stopPropagation()
  }
}


/**
 *手机号正则
 */
Vue.prototype.regPhone = /^1(3|4|5|6|7|8|9)\d{9}$/

/**
 *手机号输入框检测
 */
 Vue.prototype.registerPhoneInput = function(type='registerPhone'){
  if(this[type]){
    if(!this.regPhone.test(this[type])){
      this[type+'Tips'] = '请输入正确的手机号'
      return false
    }else{
      this[type+'Tips'] = ''
      return true
    }
  }else{
    this[type+'Tips'] = '请输入手机号'
    return false
  }
}

/**
 *输入框检测
 */
 Vue.prototype.verificationInput = function(type,tipsText){
  if(this[type]){
    this[type+'Tips'] = ''
    return true
  }else{
    this[type+'Tips'] = tipsText
    return false
  }
}

/**
 *金钱输入框检测
 */
 Vue.prototype.verificationMoney = function(e,type,tipsText){
  var value = e.target.value.toString();
  if(value){
    var value2 = this.formatPrice(value);
    if(value!=value2){
      this.$nextTick(function(){
        e.target.value = value2;
      })
    }
  }
  if(this[type]){
    this[type+'Tips'] = ''
    return true
  }else{
    this[type+'Tips'] = tipsText
    return false
  }
}

Vue.prototype.formatPrice =function(value){
  value = value.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
  value = value.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
  value = value.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')// 只能输入两个小数
  return value
 }

/**
 *格式部门tree
 */
 Vue.prototype.formatDep = function(topItem){

  let data = JSON.parse(JSON.stringify(topItem))
  let lowerDepartment = data.lowerDepartment;
  if(lowerDepartment){
    data.sList = [];
      for (let i = 0; i < lowerDepartment.length; i++) {
          let item = lowerDepartment[i];
          item.type = 1;
          item.open = true;
          item.isMore = false;
          item.name = item.departmentName;
          item.i = i;
          if(item.lowerDepartment || item.lowerPosts){
              item = this.formatDep(item)
          }
          data.sList.push(item)
      }
      if(data.sList.length==0){
        delete data.sList
      }
  }
  return data
}

/**
 *格式岗位tree
 */
 Vue.prototype.formatPost = function(topItem,postLevel){

  let data = JSON.parse(JSON.stringify(topItem))
  let lowerDepartment = data.lowerDepartment;
  data.sList = [];
  if(lowerDepartment){
      for (let i = 0; i < lowerDepartment.length; i++) {
          let item = lowerDepartment[i];
          item.type = 1;
          item.open = true;
          item.isMore = false;
          item.name = item.departmentName;
          item.i = i;
          if(item.lowerDepartment || item.lowerPosts){
              item = this.formatPost(item)
              data.sList.push(item)
          }else{
            item.isDisabled = true;
          }
          item.isDisabled = true;

      }
  }

  let lowerPosts = data.lowerPosts;
  if(lowerPosts){
      for (let i = 0; i < lowerPosts.length; i++) {
          let item = lowerPosts[i];
          item.type = 2;
          item.open = true;
          item.isMore = false;
          item.name = item.postName;
          item.i = i;
          item.level = 1;
          if(postLevel){
              item.level = postLevel;
          }
          if(item.lowerPosts){
              item = this.formatPost(item,2)
          }
          data.sList.push(item)
         
      }
  }
  if(data.sList.length==0){
    delete data.sList
  }
  return data
}



/**
 *随机字符串
 */
Vue.prototype.random_string = function (len) {
  len = len || 32;
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';   
  var maxPos = chars.length;
  var pwd = '';
  for (var i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

/**
 *文件后缀
 */
Vue.prototype.get_suffix = function (filename,type) {
  type = type || 0
  var pos = filename.lastIndexOf('.')
  var suffix = ''
  if (pos != -1) {
      suffix = filename.substring(pos+type)
  }
  return suffix;
}

Vue.prototype.uploadFile =  function (data,file,onUploadProgress,cancelCallback){
  let url = data.host;
  var formData = new FormData()
  var name = file.name;
  var key = data.dir+this.random_string()+this.get_suffix(name);
  formData.append('name', name)
  formData.append('key', key)
  formData.append('policy', data.policy)
  formData.append('OSSAccessKeyId', data.OSSAccessKeyId)
  formData.append('callback', data.callback)
  formData.append('success_action_status', 200)
  formData.append('signature', data.Signature)
  formData.append('file', file)
  var instance = axios.create();
  return new Promise(function(resolve, reject) {
      instance.post(url, formData,{
        onUploadProgress: onUploadProgress,
        cancelToken: cancelCallback
      }).then(function(res) {
        res.data.url = url+'/'+key
        resolve(res.data)
      }).catch(function(err){
        message.default.warning('上传失败')
          reject(err)
      })
  })
}

/**
 *上传图片
 */
Vue.prototype.getUploadTemp= function(file,onUploadProgress,cancelCallback)  {
  var that = this;
  return axios.post('/file/oss/uploadTemp').then(function(res){
      var data = res.data;
      return that.uploadFile(data, file,onUploadProgress,cancelCallback)
  })
}
/**
 *获取dom的css
 */
Vue.prototype.getCss = function (curEle,attr){  
  var val = null,reg = null;  
  if("getComputedStyle" in window){  
      val = window.getComputedStyle(curEle,null)[attr];  
  } else {   //ie6~8不支持上面属性  
      //不兼容  
      if(attr === "opacity"){  
          val = curEle.currentStyle["filter"];   //'alpha(opacity=12,345)'  
          reg = /^alphaopacity=(\d+(?:\.\d+)?)opacity=(\d+(?:\.\d+)?)$/i;  
          val = reg.test(val)?reg.exec(val)[1]/100:1;  
      } else {  
          val = curEle.currentStyle[attr];  
      }  
  }  
  reg = /^(-?\d+(\.\d)?)(px|pt|em|rem)?$/i;  
  return reg.test(val)?parseFloat(val):val;   
}
/**
 *创建图标错误之后的默认图div
 */
Vue.prototype.createDefaultImg = function(el,binding){
  return function(err){
    // console.log(1,'1')
    if(this.isHasSrc){return}
    this.isHasSrc = true;
    // console.log(this.src,'this')
    var oDiv = document.createElement('div');
    oDiv.className = 'default-img'
    oDiv.classList.add(el.className)
    var oSpan = document.createElement('span');
    oSpan.className = 'icon iconfont icon-tupian1';
    oSpan.style.fontSize = binding.value || '';
    oDiv.appendChild(oSpan)
    el.style.display = 'none';
    el.parentElement.insertBefore(oDiv, el); 
  }
}
/**
 *创建图标错误之后的默认图div vue-指令
 */
Vue.directive('defaultimg',{
  // 钩子函数，被绑定元素插入父节点时调用 (父节点存在即可调用，不必存在于 document 中)。
    // 只调用一次，指令第一次绑定到元素时调用，用这个钩子函数可以定义一个在绑定时执行一次的初始化动作。
  bind(el,binding){
      // console.log( 'bind' );
  },
  inserted(el,binding){  
    var fun = Vue.prototype.createDefaultImg(el,binding)
    el.onerror = fun;
    // console.log(el.src,'el.src')
    if(!el.src){
      fun.call(el)
    }
    el.onload = function(){
      var parent = el.parentElement;
      var child = parent.querySelector('.default-img')
      if(child){
        parent.removeChild(child)
      }
      el.style.display = ''
      el.isHasSrc = false;

    }
  },

  // 所在组件的 VNode 更新时调用，但是可能发生在其孩子的 VNode 更新之前。
  // 指令的值可能发生了改变也可能没有。但是你可以通过比较更新前后的值来忽略不必要的模板更新 
  update(el,binding){
    var fun = Vue.prototype.createDefaultImg(el,binding)
    el.onerror = fun;
    // console.log(el.src,'el.src')
    if(!el.src){
      fun.call(el)
    }
    el.onload = function(){
      var parent = el.parentElement;
      var child = parent.querySelector('.default-img')
      if(child){
        parent.removeChild(child)
      }
      el.style.display = ''
      el.isHasSrc = false;
    }
  },
  // 所在组件的 VNode 及其孩子的 VNode 全部更新时调用。
  componentUpdated(){
    // console.log( 'componentUpdated' );
  },
  // 只调用一次，指令与元素解绑时调用。
  unbind(el){
    // console.log( 'unbind' );
 
  }
})


//富文本转义html
function html2Escape(str){
  return sHtml.replace(/[<>&"]/g, function (c) {
  return { '<': '&lt;', '>': '&gt;', '&': '&amp;', '"': '&quot;' }[c];
});
}

/**
 *转意符换成普通字符
 */
Vue.prototype.convertIdeogramToNormalCharacter = function(val) {
  const arrEntities = {'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'};
  return val.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];});
}

// 获取富文本的纯文字内容
Vue.prototype.getPlainText = function (richCont) {
  let value = richCont;
  if(richCont){
    // 方法一： 
    value= value.replace(/\s*/g, "");  //去掉空格
    value= value.replace(/<[^>]+>/g, ""); //去掉所有的html标记
    value = value.replace(/↵/g, "");     //去掉所有的↵符号
    value = value.replace(/[\r\n]/g, "") //去掉回车换行
    value = value.replace(/&nbsp;/g, "") //去掉空格
    value = this.convertIdeogramToNormalCharacter(value);
    return value;

    // 方法二： 
    // value = value.replace(/(\n)/g, "");
    // value = value.replace(/(\t)/g, "");
    // value = value.replace(/(\r)/g, "");
    // value = value.replace(/<\/?[^>]*>/g, "");
    // value = value.replace(/\s*/g, "");
    // value = this.convertIdeogramToNormalCharacter(value);
    // return value;
  } else {
    return null;
  }
}